import {Provider} from '@preact/prerender-data-provider';
import {Router} from "preact-router";
import './styles/main.scss';
import Index from "./routes";
import OneApartment from "./routes/one-apartment";
import Payment from "./routes/payment";
import NMIPayment from "./routes/nmi-payment";
import Converge from "./routes/converge";
import davidCheckoutJsDemo from "./routes/davidCheckoutJsDemo";
import davidCheckoutJsProd from "./routes/davidCheckoutJsProd";
import davidXmlDemo from "./routes/davidXmlDemo";
import davidXmlProd from "./routes/davidXmlProd";
import Receipt from "./routes/receipt";
import Talk from "./routes/talk";

if (process.env.NODE_ENV === 'development') {
    require("preact/debug");
}

const App = (props) => {
    return (
        <Provider value={props}>
            <Router>
                <Index path="/"/>
                <Talk path="/talk"/>
                <Receipt path="/receipt"/>
                <Payment path="/payment"/>
                <NMIPayment path="/nmi-payment"/>
                <OneApartment path="/:slug"/>
                <Converge path="/converge"/>
                <davidCheckoutJsDemo path="/davidCheckoutJsDemo"/>
                <davidCheckoutJsProd path="/davidCheckoutJsProd"/>
                <davidXmlDemo path="/davidXmlDemo"/>
                <davidXmlProd path="/davidXmlProd"/>
            </Router>
        </Provider>
    )
}

export default App;
